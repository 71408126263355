<template>
  <div class="vx-col w-full mb-5" >
    <h6>{{f.field_name}} </h6>
        
      <vs-table stripe search :data="pg_requests" noDataText="No se han generado registros">
        <template slot="thead">
          <vs-th class="bold">MODELO</vs-th>
          <vs-th class="bold">MARCA</vs-th>
          <vs-th class="bold">FAMILIA DE<br>PRODUCTOS</vs-th>
          <vs-th class="bold">CANTIDAD<br>DEFINIDA</vs-th>
          <vs-th class="bold">CANTIDAD CARGADA</vs-th>
          <vs-th class="bold">ESTADO</vs-th>
          <vs-th class="bold">ACCIONES</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">

            <vs-td :data="data[indextr].product_model_id">
              {{ data[indextr].model }}
            </vs-td>

            <vs-td :data="data[indextr].product_brand_id">
              {{ data[indextr].brand }}
            </vs-td>

            <vs-td :data="data[indextr].product_group_id">
              {{ data[indextr].product_group_name }}
            </vs-td>

            <vs-td :data="data[indextr].quantity">
              {{ data[indextr].quantity }}
            </vs-td>
            
            <vs-td data="loadedStatus">
              {{ loadedStatusSerialNumbers(data[indextr]) }}
            </vs-td>

             <vs-td data="info">
              <span v-if="loadedStatus(data[indextr]) == 'completed'" class="flex items-center">
                <feather-icon icon="CheckCircleIcon" svgClasses="h-5 w-5 text-success stroke-current" />
                <span class="ml-2 small"><strong>Completo</strong></span>
              </span>
              <span v-else-if="loadedStatus(data[indextr]) == 'pending'" class="flex items-center">
                <feather-icon icon="AlertTriangleIcon" svgClasses="h-5 w-5 text-danger stroke-current" />
                <span class="ml-2 small"><strong>Pendiente</strong></span>
              </span>
              <span v-else-if="loadedStatus(data[indextr]) == 'na'" class="flex items-center">
                <span class="ml-2 small">Este producto no requiere <br>cargar números de serie</span>
              </span>
            </vs-td>

            <vs-td :data="data[indextr].id" v-if="data[indextr].product_group_serial_numbers_required">
              <vs-button v-if="!hasBackofficeAccess && !isDisabled" type="flat" class="p-2" icon="add_chart" @click.stop="editItemRequest(data[indextr])">Cargar números de serie</vs-button>
              <vs-button type="flat" color="success" class="p-2" icon="insert_chart_outlined" @click.stop="showItemRequest(data[indextr])">Ver números de serie</vs-button>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <vs-popup
        title="Agregar números de serie para el producto"
        :active.sync="showFormPrompt">
        <div class="con-exemple-prompt">
          <vs-alert v-if="onError" class="h-full mb-5" color="danger">
            <span>{{ errorMssg }}</span>
          </vs-alert>

          <p class="mb-5 bold">{{serialNumbersMssg}}</p>

          <p class="mb-3"><strong>MARCA:</strong> {{brand}}</p>
          <p class="mb-3"><strong>MODELO:</strong> {{model}}</p>
          <p class="mb-6"><strong>CANTIDAD POR CARGAR:</strong> {{quantity}}</p>        

          <vx-textarea
            class="w-full mb-4" 
            label="Ingrese los números de serie, separados por coma"
            type="text"
            height="120px"
            data-vv-name="serial_numbers"
            v-model.lazy="serial_numbers" />  

          <vs-button :disabled="!completeForm || $isLoading" @click="saveProduct" color="primary" class="mr-5 mt-2">Guardar</vs-button>
          <vs-button @click="closeFormPrompt" :disabled="$isLoading" type="flat" color="primary">Cerrar</vs-button>
            
        </div>
      </vs-popup>


      <vs-popup
        title="Números de serie cargados"
        :active.sync="showViewPrompt">
        <div class="con-exemple-prompt">
          <p class="mb-3"><strong>MARCA:</strong> {{brand}}</p>
          <p class="mb-3"><strong>MODELO:</strong> {{model}}</p>
          <p class="mb-6"><strong>CANTIDAD POR CARGAR:</strong> {{quantity}}</p>
          <p class="mb-6"><strong>CANTIDAD CARGADA:</strong> {{loadedSerialNumbersCount}}</p>

          <vx-textarea
            class="w-full mb-4" 
            label="Números de serie cargados, separados por coma"
            type="text"
            height="120px"
            disabled
            data-vv-name="serial_numbers"
            v-model="serial_numbers" />
          <vs-button @click="closeViewPrompt" color="primary">Cerrar</vs-button>            
        </div>
      </vs-popup>

    </div>

</template>

<script>
import inputHelper from "@mixins/inputHelper"
const indicationDef = "Ingresa todos los números de serie para el producto.";
const errorMssgDef = "Ha ocurrido un error guardar los datos, intente más tarde."
const successMssgDef = "Los datos han sido agregados exitosamente"
const serialNumbersMssgDef = "Por favor ingresa los números de serie para el producto definido, separando cada uno por una coma."
export default {
  name: 'pg-request-evidence-control',
  mixins: [inputHelper],
  props: {
    f: { type: Object, required: true },
    family_group_id: { type: [Number,String], required: true },
    project_fg_location_id: { type: [Number,String], required: true },
    isDisabled: { type: [String,Boolean], default: false },
  },
  data(){
    return {      
      product_model_id: null,
      model: null,
      quantity: null,
      brand: null,
      serial_numbers: null,

      getModelsForSelect: [],
      pg_requests: [],

      showFormPrompt: false,
      selected_pg_request: null,

      showViewPrompt: null,

      loadedSerialNumbersCount: 0,

      onError: false,
      onSuccess: false,
      errorMssg: errorMssgDef,
      successMssg: successMssgDef,
      serialNumbersMssg: serialNumbersMssgDef,
      formPromptIndication: indicationDef,
    }
  },
  mounted: async function(){
    //await this.getAvailableProducts();
    await this.loadSavedPgRequests();
  },
  computed: {
    completeForm(){
      return this.serial_numbers != null;
    },
    hasBackofficeAccess(){
      return this.$acl.check('isBackoffice');
    },
  },
  methods: {
    serialNumbersFormat(serial){
      let numbers = this.splitNumbers(serial);
      if(numbers.length > 0){
        let mss = numbers[0];
        if(numbers.length > 1){
          mss += `, y ${numbers.length - 1} más`;
        }
        return mss;
      }
      return "";
    },
    loadedSerialNumbers(pg){
      let numbers = this.splitNumbers(serial_numbers);
      return numbers.length;      
    },
    pendingSerialNumbers(pg){
      let numbers = this.splitNumbers(pg.serial_numbers);
      let diff = pg.quantity - numbers.length;
      if(diff < 0){
        return `${diff * -1} sobrantes`;
      }
      else{
        return diff;
      }
    },
    loadedStatusSerialNumbers(pg){
      if(pg.product_group_serial_numbers_required != true){
        return "N/A";
      }

      let count = pg.serial_numbers_count;
      let diff = pg.quantity - count;
      if(diff == 0){
        return `${count} cargados`;
      }
      else if(diff < 0){
        return `${count} cargados, ${diff * -1} sobrantes`;
      }
      else{
        return `${count} cargados, ${diff} pendientes`;
      }
    },
    loadedStatus(pg){
       if(pg.product_group_serial_numbers_required != true){
        return "na";
      }
      let numbers = this.splitNumbers(pg.serial_numbers);
      return numbers.length == pg.quantity ? "completed" : "pending";
    },
    splitNumbers(serial){
      if(serial != null && serial != ''){
        let ser = serial.trim();
        var numbers = ser.split (',').filter(item => item);
        return numbers;
      }
      else{
        return [];
      }
    },
    async loadSavedPgRequests(){
      try {
        let res = await axios.get(`/api/v1/fg_locations/${this.$props.project_fg_location_id}/getPgRequests`);
        this.pg_requests = res.data;
      }
      catch (e) {
        this.warn(e);
      }      
    },
    async getAvailableProducts(){
      try {
        let res = await axios.get(`api/v1/supplier/${this.SupplierId}/familyGroup/${this.$props.family_group_id}/getFgSupplierProductModels`);        
        let options = [];
        res.data.forEach(opt => {
          options.push({value: opt.id, text: opt.model}); //conversion a sintaxis de vs-select
        })
        this.getModelsForSelect = options;
      }
      catch (e) {
        this.warn(e);
        this.failed = true;
      }
    },
    editItemRequest(pg){
      this.selected_pg_request = pg;
      this.brand = pg.brand;
      this.model = pg.model;
      this.quantity = pg.quantity;
      this.serial_numbers = pg.serial_numbers;
      this.showFormPrompt = true;
    },
    showItemRequest(pg){
      this.serial_numbers = pg.serial_numbers;
      this.brand = pg.brand;
      this.model = pg.model;
      this.quantity = pg.quantity;
      this.serial_numbers = pg.serial_numbers;
      this.loadedSerialNumbersCount = this.countSerialNumbers(pg.serial_numbers);
      this.showViewPrompt = true;
    },
    async doRemoveItem(){
      this.showLoading(true);
      try {
        let payload = {pg_request_id: this.selected_pg_request.id};
        await axios.post(`/api/v1/fg_locations/removePgRequest`, payload);
        await this.loadSavedPgRequests();
      }
      catch (error) {
        this.warn(error);
      }
      this.showLoading(false);
    },
    async saveProduct(){
      this.onSuccess = false;
      this.onError = false;
      
      let unique = this.validateUniqueSerialNumbers();
      if(!unique){
        this.onError = true;
        this.errorMssg = "No es posible guardar la información dado que se han detectado números de serie repetidos.";
        return;
      }

      this.showLoading(true);
      try {
        let count = this.countSerialNumbers(this.serial_numbers);
        let payload = {pg_request_id: this.selected_pg_request.id, serial_numbers: this.serial_numbers, serial_numbers_count: count};
        await axios.post(`/api/v1/fg_locations/setPgRequestSerialNumbers`, payload);
        this.onSuccess = true;
        await this.loadSavedPgRequests();
        this.closeFormPrompt();
      }
      catch (error) {
        this.warn(error);
        this.errorMssg = errorMssgDef;
        this.onError = true;
      }
      this.showLoading(false);
    },
    validateUniqueSerialNumbers(){
      let numbers = this.serial_numbers.split(',');
      let unique = this.checkIfArrayIsUnique(numbers);
      return unique
    },
    countSerialNumbers(serial_numbers){
      return serial_numbers.split(',').length;      
    },
     checkIfArrayIsUnique(elements) {
      return elements.length === new Set(elements).size;
    },
    resetForm(){
      this.serial_numbers = null
      this.onError = null
      this.onSuccess = null
    },
    closeFormPrompt() {
      this.showFormPrompt = false;
      this.resetForm();
    },
    closeViewPrompt(){
      this.showViewPrompt = false;
      this.resetForm();
    },
    hasError(val){
      return this.errors.has(val);
    },
    errorText(val){
      return this.errors.first(val);
    },
    isSuccess(val){
      let ff = this.fields[val];
      return (ff && ff.required && ff.valid);      
    },

    /* check validation */
    validate(){
      return this.pg_requests.length >= 1;
    }
  }
}
</script>