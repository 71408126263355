<template>
  <div :class="{'p-6': hasBackofficeAccess}">
     <div class="vx-row" v-if="hasBackofficeAccess">
      <div class="vx-col w-full mb-3">
        <router-link
        class="decorated"
        :to="`/project/${id}`"
        >Regresar al proyecto </router-link>
      </div> 
    </div>
    
    <big-title v-if="isMounted">
      {{project.alias || "Pendiente" }} -<span> {{id}}.</span>
      <h5 class="mt-2">Integrar los datos técnicos para la tecnología - {{familyGroupName}}</h5>
      <p class="text-subtitle mt-6">Agrega los números de serie correspondientes a esta instalación.</p>

    </big-title>

    <!-- SECCIONES -->

    <div v-if="isMounted && !failed" >

      <vx-card title="Ubicación" class="mb-5">
        <p>{{full_address}}</p>
      </vx-card>
          
      <vx-card v-for="section in allSections" :key="section.id" :title="section.public_name" class="mb-5">
        <p class="mb-4" v-if="section.public_description != null">{{section.public_description}}</p>

        <div class="vx-row">
          <template v-for="f in section.fields">    

            <!-- pg_request -->
            <pg-request-evidence-control
              :key="f.id"
              :f="f"
              :family_group_id="familyGroupId"
              :project_fg_location_id="fg_id"
              :isDisabled="isDisabled"
              ref="pg_request_technical_table"         
              v-if="f.fname == 'pg_request_technical_table'" />

          </template>
        </div>

      </vx-card>
    </div>

    <load-error v-if="failed" />
    
  </div>
</template>

<script>
import inputHelper from '@components/mixins/inputHelper';
import dateHelper from '@components/mixins/dateHelper';
import formHelper from '@components/mixins/formHelper';
import FormField from '@components/forms/FormField.vue'
import PgRequestEvidenceControl from '@technologies/PgRequestEvidenceControl.vue'

const addressSelectDefinitions = [ 
 // { id: 1, label: "Misma dirección del cliente", valid: true },
  { id: 2, label: "Elegir una dirección relacionada con el proyecto", valid: true },
  { id: 3, label: "Agregar otra dirección", valid: true },
];
export default {
  props: ['id', 'fg_id'],
  mixins: [formHelper, inputHelper, dateHelper],
  data(){
    return {
      errorCode: 0,
      onError: false,
      metaData: {},
      project: {},
      fgLocation: {},
      form_id: 24,
      sections: [45],

      form: {},
      allSections: [],
      full_address: null,

      loading: false,  
      isMounted: false,
      isRefreshing: false,
      errorMssg: '',
      successMssg: '',
      failed: false,

      familyGroupName: null,
      allAddresses: [],
      familyGroupId: null,

      payload: {},

      addressSelectOptions: addressSelectDefinitions,

      clientAddress: null,
      location_id: null,
    }
  },
  components: {
    FormField,
    PgRequestEvidenceControl,
  },
  async mounted() {
    this.loading = true;
    this.isMounted = false;
    await this.getProjectInfo();
    await this.getFormInfo();
    this.isMounted = true;
    this.loading = false;
  },
  watch: {
    loading : function(newVal, oldVal){
      if(newVal == true){
         this.$vs.loading();
      }
      else {
        this.$vs.loading.close();
      }
    },
  },
  computed: {
    pg_location_exists(){
      return true;
    },
    project_fg_location_id(){
      return this.$props.fg_id;
    },
    hasBackofficeAccess(){
      return this.$acl.check('isBackoffice');
    },
    isDisabled(){
      return this.project.status == 4 || this.project.status == 8;
    },
  },
  methods: {
    async getProjectInfo() {
      try {
        let params = "with[]=projectFgLocations.familyGroup&with[]=projectFgLocations.location.address&with[]=client.user.personal.address";
        let response = await axios.get(`/api/v2/projects/${this.id}?${params}`);
        this.onError = false;
        this.project = response.data;
        this.fgLocation = response.data.project_fg_locations.find(f => f.id == this.fg_id);
        this.familyGroupName = this.fgLocation.family_group.name;
        this.familyGroupId = this.fgLocation.family_group.id;
        this.location_id = this.fgLocation.location_id;
        this.full_address = this.nestedFieldValue(this.fgLocation, 'location.address.full_address');
      }
      catch (error) {
        this.processError(error.status);
      }
    },
    async getFormInfo(){
      try{
        await this.getSectionInfo(45);

       this.allSections.forEach(s => {          
          s.fields.forEach(f => {
            this.formFieldSetter(f, this, true);
          });
        });
      }
      catch(e){
        this.warn(e);
        this.failed = true;
      }
    },
    async getSectionInfo(id){
      try{
        let response = await axios.get("/api/v1/forms/getSectionFields/" + id);
        this.allSections.push(response.data.section);
      }
      catch(e){
        this.warn(e);
        this.failed = true;
      }
    },
    processError(code){
      this.onError = true;
      this.errorCode = code;
    },    
  }
};
</script>

<style scoped>
  .alt-radio-text {
    margin-left: 10px;
    color: rgba(var(--vs-success), 1);
  }
</style>